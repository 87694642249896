.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}



.App-header {
  background-color: #e72485;
  min-height: 100svh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}




